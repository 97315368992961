<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Sit Blocks</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="green" dark @click="dialog = true" class="mb-2"
        >Add Sit Block</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      v-if="chipTransfer"
      hide-default-footer
      :items="chipTransfer.data"
      :options.sync="options"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon big class="mr-2" @click="remove(item)">delete</v-icon>
      </template>
      <template v-slot:item.createdDate="{ item }">
        <div>{{ new Date(item.createdDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.user="{ item }">
        <UserDetailCard
          :userId="item.user.id"
          :pictureUrl="item.user.pictureUrl"
          :userName="item.user.userName"
          :level="item.user.level"
          :flag="item.user.flag"
          :isOnline="item.user.isOnline"
        />
      </template>
      <template v-slot:item.friend="{ item }">
        <UserDetailCard
          :userId="item.friend.id"
          :pictureUrl="item.friend.pictureUrl"
          :userName="item.friend.userName"
          :level="item.friend.level"
          :flag="item.friend.flag"
          :isOnline="item.friend.isOnline"
        />
      </template>
      <template v-slot:item.user.chip="{ item }">
        <div>{{ item.user.chip | formatMoney }}</div>
      </template>
      <template v-slot:item.friend.chip="{ item }">
        <div>{{ item.friend.chip | formatMoney }}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :length="chipTransfer.totalPage"
        :total-visible="20"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Sit Block</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="From UserId"
                  placeholder="UserId"
                  v-model="fromId"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="To UserId"
                  placeholder="UserId"
                  v-model="toId"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ChipDetailCard",
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  props: {
    search: {
      type: String,
      description: "search",
      defaultValue: null,
    },
  },
  data() {
    return {
      dialog: false,
      currentPage: 1,
      chipTransfer: {},
      fromId: null,
      toId: null,
      options: {},
      headers: [
        { text: "From User", value: "user" },
        { text: "From Chip", value: "user.chip" },
        { text: "From GoldAmount", value: "user.goldAmount" },
        { text: "To User", value: "friend" },
        { text: "To Chip", value: "friend.chip" },
        { text: "To GoldAmount", value: "friend.goldAmount" },
        { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.chipTransfer = await this.$store.dispatch("sitBlocks/getAll", {
        page: this.currentPage - 1,
        search: this.search,
        options: this.options,
      });
    },
    async save() {
      await this.$store.dispatch("sitBlocks/add", {
        fromId: this.fromId,
        toId: this.toId,
      });
      this.fromId = null;
      this.toId = null;
      this.initialize();
      this.dialog = false;
    },
    async remove(item) {
      await this.$store.dispatch("sitBlocks/remove", {
        fromId: item.user.id,
        toId: item.friend.id,
      });
      this.initialize();
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
  },
  async mounted() {},
};
</script>
